// import { ID, Maybe, WebAppMetrics, isDefined } from "@technis/shared";
import { firebaseDb } from "../../../..";
import { useEffect, useMemo, useRef, useState } from "react";
import { off, onValue, ref } from "firebase/database";
import { usePrevious } from "@technis/ui";
import { DISCONNECT_CHECK_INTERVAL } from "../../../../common/constants";
import { ID } from "@technis/shared";
import { useQuery } from "react-apollo";
import gql from "graphql-tag";
import { HttpLink } from "apollo-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";
import ApolloClient from "apollo-client";

const VISITORS_ZONE_ID = 4969;
const CAPTURE_RATE_ZONE_ID = 4970;
const DWELL_ZONE_ID = 4970;

interface Result {
  visitorCount: number;
  captureCount: number;
  averageDwellTime: number;
}

export const useStatistics = (token: string | undefined): Result => {
  const [values, setValues] = useState<Result>({
    averageDwellTime: 0,
    captureCount: 0,
    visitorCount: 0,
  });

  const isConnectedRef = useRef(false);

  const url = "/production/liveKPIs/3/2422/zones";

  const startListening = () => {
    isConnectedRef.current = true;
    const countRef = ref(firebaseDb, url);

    onValue(
      countRef,
      (snapshot) => {
        const newValue = snapshot.val();

        const visitorCount = newValue[VISITORS_ZONE_ID]["UNIQUE_VISITORS_IN"];
        const captureCount = newValue[CAPTURE_RATE_ZONE_ID]["UNIQUE_VISITORS_IN"];
        const averageDwellTime = Math.round(newValue[DWELL_ZONE_ID]["DWELL"]);

        setValues({
          visitorCount,
          captureCount,
          averageDwellTime,
        });
      },
      (err) => {
        console.log(err);
        isConnectedRef.current = false;
      },
    );
  };

  const stopListening = () => {
    off(ref(firebaseDb, url));
    isConnectedRef.current = false;
  };

  const prevToken = usePrevious(token);

  // Logic to keep firebase alive
  useEffect(() => {
    if (prevToken && isConnectedRef.current) {
      stopListening();
    }
    startListening();
  }, [token]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isConnectedRef.current) {
        startListening();
      }
    }, DISCONNECT_CHECK_INTERVAL);
    return () => {
      clearInterval(interval);
      if (isConnectedRef.current) {
        stopListening();
      }
    };
  }, []);

  return values;
};

const publicClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: new HttpLink({
    uri: "https://api-application.technis.com/api/v2",
  }),
});

const OLD_DAYS_QUERY = (zoneId: number, kpiName = "UNIQUE_VISITORS_IN") => gql`
  query getKPIs {
    getKPIs(zoneId: ${zoneId}, kpiName: ${kpiName}, dateBegin: "2024-01-09", dateEnd: "2024-01-12", granularity: DAY)
  }
`;

export const useOldDaysQuery = (zoneId: ID) => {
  const { data } = useQuery(OLD_DAYS_QUERY(zoneId), {
    client: publicClient,
    fetchPolicy: "network-only",
    pollInterval: 600000,
    context: {
      headers: {
        Authorization:
          // eslint-disable-next-line max-len
          "Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1aWQiOiJ6c3dJSFVUS3h4TksxU081QVZhbmozZlFnbDYyIiwiaXNzIjoiZmlyZWJhc2UtYWRtaW5zZGstZjhkYzhAdGVjaG5pcy1jb3VudGluZy5pYW0uZ3NlcnZpY2VhY2NvdW50LmNvbSIsInN1YiI6ImZpcmViYXNlLWFkbWluc2RrLWY4ZGM4QHRlY2huaXMtY291bnRpbmcuaWFtLmdzZXJ2aWNlYWNjb3VudC5jb20iLCJhdWQiOiJodHRwczovL2lkZW50aXR5dG9vbGtpdC5nb29nbGVhcGlzLmNvbS9nb29nbGUuaWRlbnRpdHkuaWRlbnRpdHl0b29sa2l0LnYxLklkZW50aXR5VG9vbGtpdCIsImlhdCI6MTcwNDQ2NDE1MywiY2xhaW1zIjp7ImlzQXBpS2V5Ijp0cnVlLCJ0b2tlblR5cGUiOiJwdWJsaWMifX0.gzWG_jODZ5AGa05KtFAScZ2VMARcgVbtUQ9MU9z_VE1AUURYHLef0083acTxg_RArK86QOFfZYEGMPnER_62eGAzlH25IQZkSwt_cgskDTCwO064UjCiEh2qx4HvwmxkR10FnyV6VNGU4klYgoE1ubZKydxSVOl9NHZ022pLKOEzMIMDZRdqPL4zl7CD-gxCRKRLXrPHi3K65hlOd8z_r1kTKsNlJZUQCC8tNmmSGi9qVJGQfG1jejfrqy2eigftTjDwGhj5-TpJ8aP7Q-jEaJXyE_KMta8yR8xEFZZqwbylNHQCJx6nhJ6K2EUlTmMeRTZS43hh1nOQpW1vCMM2Rg",
      },
    },
  });

  const cumulate = useMemo(() => {
    if (data?.getKPIs) {
      const values = data.getKPIs.values.slice(0, -1);

      return values.map((v: { value: number }) => v.value).reduce((partialSum: number, a: number) => partialSum + a, 0);
    }
    return 0;
  }, [data]);

  return cumulate;
};
